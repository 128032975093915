import React from 'react';
import { Box, Button, Grid, Typography, Breadcrumbs, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ShareIcon from '@mui/icons-material/Share';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from 'react';
import { JOBAPPLICANT, REQUESTS,getUser } from '../../../services/types';


const JobDetailCard = ({title, logo, category , job, onApplayClick}) => {
  let navigate = useNavigate()
  let dispatch = useDispatch()
  
  
  return (
    <Box p={2}>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/companies">
          Companies
        </Link>
        <Link underline="hover" color="inherit" href="/companies/nomad">
          Nomad
        </Link>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
      <Box mt={2} p={2} border={1} borderRadius={1} borderColor="grey.300">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img
              src={logo?logo:"https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80adfd990746d99e2432126a059f38bf940d1c089cd59e2f1a1e21f069f4d522?apiKey=3cdd01a866254c9ba27f523a88497719&"}
              alt="Company Logo"
              style={{ borderRadius: 8 }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h4">{category}</Typography>
            <Typography variant="body2" color="textSecondary">
              Stripe • Paris, France • Full-Time
            </Typography>
          </Grid>
          <Grid item>
            <ShareIcon color="action" />
          </Grid>
          <Grid item>
          <div className="job-title">
            {/* user logged and job not applied before */}
            {(getUser()?.main_role == "User" && !job.is_applied) && <>
                {/* user loggredin and job selected */}
                <Button sx={{ pl: 4, pr: 2, textAlign: 'right' }} onClick={() => {
                    let formData = { job: job.id }
                    dispatch({
                        type: REQUESTS.APPLYJOB.POST.ACTION, payload: formData, callback: (resp) => {
                            if (resp?.id) {
                                onApplayClick(resp)
                            }
                        }
                    });
                    console.log("Applied job", job)
                }}>Apply</Button>
            </>}
            {(getUser()?.main_role == "Company" && !job.is_applied) && <>
                {/* user loggredin and job selected */}
                <Button sx={{ pl: 4, pr: 2, textAlign: 'right' }} onClick={() => {
                    navigate('/applicants/',{state:{data:job}})
                }}>View Applicant's List</Button>
            </>}
            {/* user not logged in */}
            {!getUser() && <>
                <Button onClick={() => { navigate("/login/") }}>Login to apply</Button>
                <Button onClick={() => { navigate("/sign_up/") }}>Register to apply</Button>
            </>}

        </div>
          
          </Grid>
        </Grid>
      </Box>
    </Box>
    
  );
  
};

export default JobDetailCard;
