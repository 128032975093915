import { Grid, Typography, Button, Box, TextField, Divider, Tab, Container, CardMedia } from '@mui/material';
import React from 'react';
import XForm from '../../libs/inputs/XForm';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUESTS } from "../../services/types";
import axios from 'axios';
import { TabList, TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import ButtonAppBar from '../home/fragments/ButtonAppBar'
import EdiLabel from '../../libs/inputs/EdiLabel';
import PostJobBar from './fragments/PostJobBar';
import ImageCard from '../../libs/inputs/ImageCard';

export default function Profile() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let auth = useSelector((state) => state.auth.data);

    const [tab, setTab] = React.useState(1 + "")
    const onSubmit = (e, data) => {
        if (tab == "2") {
            data.main_role = "Company"
        }
        let action = data.id
            ? REQUESTS.PUBLICUSER.PATCH.ACTION
            : REQUESTS.PUBLICUSER.POST.ACTION;
        dispatch({ type: action, payload: data });
    };

    return (<>
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <PostJobBar/>
                    <Typography variant="h4" gutterBottom>
                        Your Company Profile
                    </Typography>
                    <Grid container spacing={4} className="padding-top-20">
                        <Grid item xs={12} md={6}>
                            <ImageCard/>
                            <CardMedia image={auth.avatar_full_path}>
                                <img
                                    loading="lazy"
                                    src="https://uploads-ssl.webflow.com/6480217dd2b60074b15929c5/64896e2f14d2fc07ffa79526_Auth%20Page%20-%20Sidebar%20Illustration.png"
                                    style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                />

                            </CardMedia>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EdiLabel label={'Company Name'} variant="4" defaultValue={auth?.first_name} />
                            <EdiLabel label={'Phone'} variant="6" defaultValue={auth?.phone} />
                            <EdiLabel label={'Email'} variant="6" defaultValue={auth?.email} />
                            <EdiLabel label={'Country'} variant="6" defaultValue={auth?.email} />
                            <EdiLabel label={'State'} variant="6" defaultValue={auth?.email} />
                            <EdiLabel label={'City'} variant="6" defaultValue={auth?.email} />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12} md={6}>
                    
                </Grid>
            </Grid>
        </Container>
    </>
    );
}
