import { Grid, Box, Typography, Card, Container, FormControl, Select, MenuItem, IconButton } from '@mui/material';
import React from 'react'
import ButtonAppBar from '../home/fragments/ButtonAppBar'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Footer from '../footer/Footer'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUESTS, getUser } from "../../services/types";
import List from '../jobs/fragments/List';
import axios from 'axios';
import SearchBox from '../jobs/fragments/SearchBox';
import SideFilter from '../jobs/fragments/SideFilter';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import FindMy from './FindMy';

function JobSearch() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let company = useSelector((state) => state.pubjob.data);
    const [open, setOpen] = React.useState(false)
    let userObj = getUser()
    const [sortBy, setSortBy] = React.useState('Most Relevant');

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };
    React.useEffect(() => {

        delete axios.defaults.headers.common['Authorization']
        let action = REQUESTS.PUBJOB.GET.ACTION;
        dispatch({ type: action, payload: "/public/job/0/" });
        console.log("USER ****", userObj)
    }, [])
    return (
        <>
            <Container>

                <Grid item xs={12} md={6}>
                    <Box>
                        <ButtonAppBar />
                    </Box>
                </Grid>

                <FindMy title={"job"} />

                <Grid item xs={12} sx={{ mb: 4 }}>
                    <SearchBox />
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={4}>
                        <SideFilter />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: { xs: 'space-between', md: 'space-between' },
                                mb: 2
                            }}
                        >
                            <Box>
                                <Typography
                                    variant='h5'
                                    fontFamily='sans-serif'
                                    fontWeight='700'
                                    sx={{ display: { xs: 'inline', md: 'block' } }} // Visible on all sizes
                                >
                                    All Jobs
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: { xs: 'auto', md: 'auto' },
                                }}
                            >
                                <Typography
                                    variant='subtitle1'
                                    color='gray'
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'block' } // Hidden on mobile
                                    }}
                                >
                                    Sort by:
                                </Typography>

                                <FormControl
                                    variant="outlined"
                                    sx={{ ml: { xs: 0, md: 2 }, minWidth: 120 }}
                                >
                                    <Select
                                        value={sortBy}
                                        onChange={handleSortChange}
                                        sx={{ height: 40 }}
                                    >
                                        <MenuItem value="Most Relevant">Most Relevant</MenuItem>
                                        <MenuItem value="First Choice">First Choice</MenuItem>
                                        <MenuItem value="Second Choice">Second Choice</MenuItem>
                                        <MenuItem value="Third Choice">Third Choice</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}> {/* Hidden on mobile */}
                                    <IconButton
                                        sx={{
                                            transition: 'transform 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.2)',
                                            }
                                        }}
                                    >
                                        <GridViewOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            transition: 'transform 0.3s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.2)',
                                            }
                                        }}
                                    >
                                        <ViewAgendaIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <List results={company?.results} onApplayClick={
                           
                            (data,status) => {
                                if (userObj) {
                                    setOpen(true)
                                } else {
                                    localStorage.setItem("selectedjob", JSON.stringify(data))
                                    navigate("/job_details/")
                                }
                            }
                        }
                        />
                    </Grid>
                </Grid>


            </Container>
            <Grid container className='padding-top-20'>
                <Grid item xs={12} md={12}>
                    <Box>
                        <Footer />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default JobSearch