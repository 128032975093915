import * as React from "react";
import { CImg, Input, XForm, K } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Grid } from "@mui/material";
import ButtonAppBar from "../home/fragments/ButtonAppBar";

export default function Register({ db_plan1 }) {
    const [db_plan, setDbPlan] = React.useState()
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.DB_PLAN_UPDATE.SEARCH.ACTION, payload: `/db_plan_v2/14/`, no_update: true, callback: (data) => {
                setDbPlan(data)
            }
        })
    }, [])

    return <>
        <ButtonAppBar />
        <Box sx={{ flexGrow: 1, mt: 1, }}>
            <Grid container >
                <Grid item xs={12} md={3} style={db_plan?.Register?.style?.box}>
                    <XForm>
                        <h5 style={db_plan?.Register?.style?.title}>{K(db_plan?.Register?.text?.title, "Register?.text?.title")}</h5>
                        <h6 style={db_plan?.Register?.style?.subtitle}>{K(db_plan?.Register?.text?.subtitle, "Register?.text?.subtitle")}</h6>
                        <CImg name={"avatar"} type={"avatar"} />
                        <Input required name={"name"} label={K(db_plan?.Register?.text?.name, "Register?.text?.name")} />
                        <Input name={"what_app_number"} label={K(db_plan?.Register?.text?.what_app_number, "Register?.text?.what_app_number")} />
                        <Input name={"dob"} label={K(db_plan?.Register?.text?.dob, "Register?.text?.dob")} />
                        <Input type={"select"} name={"join_as"} label={K(db_plan?.Register?.text?.join_as, "Register?.text?.join_as")} />
                        <Button type={"submit"}> {K(db_plan?.Register?.text?.btn_submit_text, "Register?.text?.btn_submit_text")}</Button>
                        <p style={db_plan?.Register?.style?.footer}>{K(db_plan?.Register?.text?.footer, "Register?.text?.footer")}</p>
                    </XForm>
                </Grid>
                <Grid item md={9} xs={12} style={db_plan?.Register?.style?.box}>
                </Grid>
            </Grid>
        </Box>

    </>
}