import axios from 'axios';
// const baseURL = baseUrl()
const baseURL = process.env.REACT_APP_URL
axios.defaults.baseURL = baseURL
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// Get All Todos
let user  = undefined
try{
    user = JSON.parse(localStorage.getItem("auth")||'{}')
}catch(err){
    
}
console.log("cached user", user)
if (user) {
    console.log("token applied", user)
    axios.defaults.headers.common['Authorization'] = "Token " + user.token
    console.log("header", axios.defaults.headers.common['Authorization'])
} else {
    axios.defaults.headers.common['Authorization'] = null
}
const handleError = (error) => {
    console.log("mesasge", error)
    if (error.response.status == 403) {
        return { status: error.response.status, error: true, message: error.response.data }
    }
    if (typeof error?.response?.data == 'object') {
        error.response.data = Object.keys(error.response.data).map((key) => error.response.data[key].join(",").replace("This",key)).join(" ")
    }
    return { status: error.response.status, error: true, message: error.response.data }
}
export const getRequest = async (url) => {
    try {
        const todos = await axios.get(url)
        return todos.data;
    } catch (err) {
        return handleError(err)
    }
}

// Create New Todo
export const postRequest = async (url, data) => {
    if (url == "token/") {
        axios.defaults.headers.common["Authorization"] = null;
    }
    // axios.defaults.headers.common["content-type"] = 'multipart/form-data'
    console.log("post called", url)
    try {
        const todo = await axios.post(url, data)
        return todo.data;
    } catch (err) {
        return handleError(err)
    }
}
export const putRequest = async (url, data) => {
    console.log("put called", url)
    try {
        const todo = await axios.put(url, data);
        return todo.data;
    } catch (err) {
        return handleError(err)
    }
}
//patch
export const patchRequest = async (url, data) => {
    console.log("patch called", url)
    try {
        const todo = await axios.patch(url, data);
        return todo.data;
    } catch (err) {
        return handleError(err)
    }
}


// Delete existed todo
export const delRequest = async (url, id) => {
    console.log(url, id)
    url = `${url}${id}/`
    try {
        let data = await axios.delete(url)
        console.log("DEL DATA", data);
        return id
    } catch (err) {
        return handleError(err)
    }
}

