import * as React from "react";
import { Button, TextField } from '@mui/material';
import { css as customCss } from '../../content/Style'
import ZModal from '../../libs/ZModal';
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import XForm from "../../libs/inputs/XForm";
export default function ChatBox({ inputs, open, onClose, step, onSubmit, data }) {
    const [curIdx, setCurIdx] = React.useState(0)
    onSubmit = onSubmit ? onSubmit : () => { }
    step = step ? step : 1
    const [newInputs, setNewInputs] = React.useState([])
    React.useEffect(() => {
        next()
    }, [])
    const next = () => {
        setCurIdx((curIdx + 1) % inputs?.length);
        setNewInputs(inputs.slice(curIdx, curIdx + step));
    }
    const prev = () => {
        setCurIdx((curIdx + inputs?.length - 1) % inputs?.length);
        setNewInputs(inputs.slice(curIdx, curIdx + step));
    }
    return <>
        <ZModal showModal={open} onClose={onClose}>
            <XForm onSubmit={onSubmit} data={data}>
                <input name="id" type="hidden" />
                {newInputs?.map((input) => <TextField
                    fullWidth
                    label={input?.label}
                    name={input?.name}
                    variant="outlined"
                    margin="normal"
                    type={input?.type}
                />)}


                <Button onClick={() => { prev() }}> <ArrowLeft /> </Button>
                <Button onClick={() => { next() }}> <ArrowRight /> </Button>
                <Button type="submit">  SAVE </Button>
            </XForm>


        </ZModal>
    </>
}