import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuBox from '../layout/MenuItem';
import { v4 as uuidv4 } from 'uuid';
import { css } from '../content/Style';

export default function XSideBar({ menuItems, drawerWidth, showLabel, showDivider, menuFilter ,handleDrawerClose }) {
  return (
    <>
      {menuItems.map((menuItem, index) => {
        if (menuItem.noMenu) { return null }
        console.log("Menu List :", menuFilter)
        if (menuFilter.includes(menuItem?.permission)) {
          return (
            <>
              <List style={{ ...css.SideBar }} key={index}>
                <MenuBox
                  key={uuidv4()}
                  name={menuItem.name}
                  exact={"true"}
                  to={menuItem.to}
                  subMenus={menuItem.subMenus || []}
                  icon={menuItem.icon}
                  isExpand={menuItem.isExpand}
                  onClick={(e) => {
                    menuItem.isExpand = menuItem.isExpand ? false : true
                    handleDrawerClose()
                  }}
                  drawerWidth={drawerWidth}
                  showLabel={showLabel}
                />
              </List>
              {showDivider && <Divider />}
            </>)
        }
      })}

    </>
  );
}