import { call, put } from 'redux-saga/effects';
import { getRequest, postRequest, patchRequest, delRequest, putRequest } from '../apis/api';
import Swal from 'sweetalert2'
export let DEFAULT_LOADER_AND_MESSAGE = true
const SUCCESS = "success"
const FAILED = "error"
const FILL = "FILL"
const APPEND = "APPEND"
const REPLACE = "REPLACE"
const REMOVE = "REMOVE"
const DO_NOTHING = "DO_NOTHING"
const API_FAILED = "API_FAILED"
const SUCCESS_MESSAGE = "Successfully updated....."
const FAILED_MESSAGE = "Failed to update"
let REQUESTS = {}
export function isNumber(value) {
    if (typeof value === "string") {
        return !isNaN(value);
    }
    return !isNaN(value)
}
export const setRequest = (REQ) => {
    REQUESTS = REQ
}
const fire = async (type, message) => {
    Swal.close()
    let out = setTimeout(() => {
        console.error("Swal ", type)
        Swal.fire({
            title: "",
            text: message,
            timer: 2500
        }).then((value) => {
            clearTimeout(out)
        })
    }, 1000)
}
// const handleError = (code,message)=>{
//     fire(FAILED, message)
// }
// ---  Should  not update  code----
let ACTION = (action) => {
    return action.replace(/_GET|_SEARCH|_POST|_PUT|_PATCH|_DELETE/gi, function (matched) {
        return "";
    }).trim();
}

export function* getUser() {
    console.log("HELLO")
    yield put({"MODULE": "LOGIN", "type": "ISLOGGED"});
}

export function* _search({ type, payload, callback }) {
    type = ACTION(type)
    let is_load = REQUESTS[type]["SEARCH"]["defaultLoader"]
    if (is_load) Swal.showLoading();
    try {
        const data = yield call(getRequest, payload);
        Swal.close();
        if (callback) callback(data)

    } catch (error) {
        if (callback) callback([])
        Swal.close();
    }
}
export function* _get({ type, payload, cache, callback }) {
    type = ACTION(type)
    let is_load = REQUESTS[type]["GET"]["message"]
    if (is_load) Swal.showLoading();
    let API_URL = REQUESTS[type]["GET"]["URL"]
    let url = payload;
    if (isNumber(payload)) {
        payload = payload ? payload : 0;
        url = `${API_URL}/${payload}/`
    }
    console.log("request called", url)
    try {
        const data = yield call(getRequest, url);
        if (data.error) {
            yield put({ "MODULE": type, "type": type + "_FAILED", "data": data, action: DO_NOTHING });
            Swal.close()
            if (data?.status) fire(FAILED, data?.message)
            if (callback) callback(data)
        } else if (data) {
            yield put({ "MODULE": type, "type": type + "_SUCCESS", "data": data, action: FILL, cache: cache });
            Swal.close()
            if (callback) callback(data)
        } else {
            yield put({ "MODULE": type, "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
            Swal.close()
            if (callback) callback(data)
        }
        Swal.close()
    } catch (error) {
        yield put({ "MODULE": type, "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
        Swal.close()
    }
}
export function* _post({ type, payload, callback, cache }) {
    type = ACTION(type)
    let is_load = REQUESTS[type]["POST"]["message"]
    try {
        if (is_load) Swal.showLoading();
        console.log("saveNote", payload)
        if ("FETCHDIRECT" == REQUESTS[type]["POST"]["URL"]) {
            yield put({ "type": type, "data": payload, action: APPEND, cache: cache });

        } else {
            let url = REQUESTS[type]["POST"]["URL"] + "/"
            if (payload.hasOwnProperty("url")) {
                url = payload.url
                payload = payload.payload
            }
            const data = yield call(postRequest, url, payload);
            if (data.error) {
                if (callback) callback(data)
                console.log("error1:", JSON.stringify(data));
                if (is_load) fire(FAILED, data?.error ? data.message : FAILED_MESSAGE)
                yield put({ "type": type + "_FAILED", "data": data, action: DO_NOTHING });
            } else if (data) {
                console.log("new data - ", data, " type -", type)
                yield put({ "type": type + "_SUCCESS", "data": data, action: APPEND, cache: cache });
                if (callback) callback(data)
                if (is_load)
                    fire(SUCCESS, is_load?.success ? is_load?.success : SUCCESS_MESSAGE)
            } else {
                console.log("error2:", JSON.stringify(data));
                yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
                if (is_load)
                    fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
            }
            if (callback) callback(data)
        }


    } catch (error) {
        console.log("error3:");
        yield put({ "type": API_FAILED, "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
        fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
    }

}
export function* _put({ type, payload, callback, cache }) {
    type = ACTION(type)
    let is_load = REQUESTS[type]["PUT"]["message"]
    if (is_load) Swal.showLoading();
    let API_URL = REQUESTS[type]["PUT"]["URL"]
    try {
        console.log("putNote", API_URL, payload)
        const data = yield call(putRequest, `${API_URL}/${payload.id}/`, payload);
        if (data.error) {
            console.log("error1", data)
            yield put({ "type": type + "_FAILED", "data": data, action: DO_NOTHING });
            if (is_load)
                fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
        } else if (data) {
            yield put({ "type": type + "_SUCCESS", "data": data, action: REPLACE, cache: cache });
            if (callback) callback(data)
            console.log("SUCCESS *****", is_load)
            if (is_load)
                fire(SUCCESS, is_load?.success ? is_load?.success : SUCCESS_MESSAGE)
        } else {
            if (is_load)
                fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
        }
        if (callback) callback({ error: true, message: "Unknown Error" })

    } catch (error) {
        console.log("exception", error)
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
        fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
    }
}
export function* _patch({ type, payload, callback, cache }) {
    type = ACTION(type)
    let is_load = REQUESTS[type]["PATCH"]["message"]
    if (is_load) Swal.showLoading();
    let API_URL = REQUESTS[type]["PATCH"]["URL"]
    try {
        console.log("patchNote", payload)
        const data = yield call(patchRequest, `${API_URL}/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", "data": data, action: DO_NOTHING });
            if (is_load)
                fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
        } else if (data) {
            yield put({ "type": type + "_SUCCESS", "data": data, action: REPLACE, cache: cache });
            if (callback) callback(data)
            if (is_load)
                fire(SUCCESS, is_load?.success ? is_load?.success : SUCCESS_MESSAGE)

        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
            if (is_load)
                fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
        }
        if (callback) callback(data)
    } catch (error) {
        console.log("Error", error)
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
        // fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
    }

}
export function* _del({ type, payload, callback, cache }) {

    type = ACTION(type)
    let is_load = REQUESTS[type]["DELETE"]["message"]
    if (is_load) Swal.showLoading();

    let API_URL = REQUESTS[type]["DELETE"]["URL"]
    try {
        console.log("delete", payload)
        const data = yield call(delRequest, `${API_URL}/`, payload);
        if (data.error) {
            yield put({ "type": type + "_FAILED", "data": data, action: DO_NOTHING });
            if (is_load)
                fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
        } else if (data) {
            console.log("deleted data - ", data)
            yield put({ "type": type + "_SUCCESS", "data": data, action: REMOVE, cache: cache });
            if (is_load)
                fire(SUCCESS, is_load?.success ? is_load?.success : SUCCESS_MESSAGE)
        } else {
            yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
            if (is_load)
                fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": type + "_FAILED", "data": { error: true, message: "Unknown Error" }, action: DO_NOTHING });
        if (callback) callback({ error: true, message: "Unknown Error" })
        fire(FAILED, is_load?.error ? is_load?.error : FAILED_MESSAGE)
    }

}

// ---  Should  not update above code----