import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import FeatureCard from './JobCard'

// job list
function List({ results, onClick, title, user, onApplayClick, tabular }) {
    return (
        <Grid container spacing={2} >
            {/* <Grid item xs={12} md={12}  >
                <Box className='padding-top-20 padding-bottom-10' >
                    <Typography sx={{ fontSize: '32px' }} >{title ? title : "All"} <span style={{ color: '#26A4FF' }} ></span></Typography>
                </Box>
            </Grid> */}
            {
                tabular ?
                    <> <Grid item xs={12} sm={12} md={12}>
                        <Box>
                            <Box sx={{border:'1px solid gray',height:30}}></Box>
                            <table width="100%" border='1'>
                                <tr>
                                    <th>Roles</th>
                                    <th>Status</th>
                                    <th>Date Posted</th>
                                    <th>Due Date</th>
                                    <th>Job Type</th>
                                    <th>Applicants</th>
                                    <th>Needs</th>
                                </tr>
                                {results?.map((data) => <>
                                    <tr>
                                        <td>{data.job_title}</td>
                                        <td>{data.live}</td>
                                        <td>{data.created_at}</td>
                                        <td>{data.due_date}</td>
                                        <td>{data.job_type}</td>
                                        <td>{data.no_applicants}</td>
                                        <td>{data.vacancy}</td>

                                    </tr>
                                </>)}
                            </table>
                        </Box>
                    </Grid>
                    </>
                    : results?.map((data) => <>
                        <Grid item xs={12} sm={12} md={12} key={data?.id}>
                            <Box>
                                <FeatureCard data={data} user={user} onApplayClick={onApplayClick} />
                            </Box>
                        </Grid>
                    </>)}
        </Grid>
    )
}

export default List