import * as React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import ImgInput from './ImgInput';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import './style.css'
export default function EdiLabel(props) {
    const objectConstructor = ({}).constructor;
    let { name, label, style, primary, secondary } = props
    const [editable, setEditable] = React.useState(0)
    const [value, setValue] = React.useState(props?.defaultValue ? props?.defaultValue : label)
    const [warns, setWarns] = React.useState(props?.defaultValue ? false : true)
    const covercss = {
        position: 'relative',
        display: 'inline-block',
        marginBottom: 30,
        Padding: 3,
        boxSizing: 'border-box',
        width: '100%',
    }
    const labelCss = {
        position: 'absolute',
        top: -6,
        left: 5,
        margin: 'auto',
        boxSizing: 'border-box',
        fontSize: 11,
        background: '#ffff',
        paddingLeft: '4px',
        paddingRight: '4px',
        borderRadius: 8,
    }
    const inputCss = {
        width: '100%',
        height: 28,
        border: '1px solid #c5d6e9',
        borderRadius: 8,
        paddingLeft: 8,
        ...props?.style
    }
    label = label ? label : name
    const class_name = "float-input"
    const placeholder = label
    label = primary ? "" : label

    if (["hidden"].includes(props?.type)) {
        return (
            <input {...props} />
        )
    }
    if (["image"].includes(props?.type)) {
        return (
            <div style={covercss}>
                {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
                <ImgInput {...props} style={{ ...inputCss, paddingTop: 4, paddingBottom: 4 }} />
                {(!primary && !secondary) && <label for={name} style={labelCss}>{label}</label>}
            </div>
        )
    }

    if (["radio", "checkbox"].includes(props?.type)) {
        return (
            <div style={covercss}>
                <input {...props} /><label>{label}</label>
            </div>
        )
    }
    if (["textarea"].includes(props?.type)) {
        return (
            <div style={covercss}>
                {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
                <textarea id={name}  {...props} style={{ ...inputCss }} className={class_name} placeholder={label} ></textarea>
                {(!primary && !secondary) && <label for={name} className="floating-label" style={labelCss}>{label}</label>}
            </div>
        )
    }
    if (["select"].includes(props?.type)) {
        return (
            <div style={covercss}>
                {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
                <select className={class_name} {...props}>
                    <option value="">Select {props?.label ? props?.label : props?.name}</option>
                    {props?.options?.map((option, i) => objectConstructor == option.constructor ? <option key={i} value={option[props?.val]}>{option[props?.text]}</option> : <option>{option}</option>)}
                </select>
                {(!primary && !secondary) && <label for={name} className="floating-label" style={labelCss}>{label}</label>}
            </div>
        )
    }
    return (
        <Box sx={{ display: 'flex', position: 'relative' }} alignItems={'center'} className="editable">
            {editable ?
                <>
                    <input className="editable-box" placeholder={label} value={value}
                        onChange={(e) => setValue(e.target.value)} />
                    <span className="editable-btn" onClick={() => { setEditable(!editable); }}>
                        <DoneIcon sx={{ p: .4 }} />
                    </span>
                </>
                :
                <>
                    <Typography {...props} >{value} {warns && <Tooltip title={"please Update " + label}>
                        <InfoIcon sx={{ color: 'red', fontSize: 14 }} /></Tooltip>}
                    </Typography>
                    <span className="editable-btn" onClick={() => { setEditable(!editable) }}>
                        <CreateIcon sx={{ p: .4 }} />
                    </span>
                </>
            }
        </Box>
    )
}