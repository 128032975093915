import React, { useEffect } from "react";
import { Offline, Online } from "react-detect-offline";
import "./App.css";
import Layout from "./layout/Layout";
import AnonyLayout from "./layout/AnonyLayout";
import NoInternet from './pages/offline/NoInternet'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function App() {
  const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
  let authState = useSelector((state) => state.auth.data)
  let user = null
  try {
    user = JSON.parse(localStorage.getItem("auth") || '{}')
  } catch (err) { }
  console.log("STATE", authState, "USER", user)
  const dispatch = useDispatch()
  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = "Token " + user?.token
    if (user && authState?.token != user?.token) {
      dispatch({ type: "ISLOGGED", payload: user })
    }
  }, [user, authState]);
  return (
    <>
      {/* <Offline> */}
      {/* <NoInternet /> */}
      {/* {auth?.data?.token ? <Layout isLogged={auth?.data?.token} name={PROJECT_NAME} ></Layout> : <AnonyLayout />} */}
      {/* </Offline> */}
      {/* <Online> */}
      {user?.token ? <Layout isLogged={user?.token} user={user} name={PROJECT_NAME} ></Layout> : <AnonyLayout />}
      {/* </Online> */}
    </>
  );
}
export default App;
