import { Box, Button, Container, Grid, Typography, Modal, TextField } from '@mui/material'
import React from 'react'
import ButtonAppBar from '../home/fragments/ButtonAppBar'
import FeatureCard from './fragments/JobCard'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Footer from '../footer/Footer'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUESTS, getUser } from "../../services/types";
import List from './fragments/List';
import ZModal from '../../libs/ZModal';
import { XForm } from '../../libs/Libs';

function JobSearch({ all }) {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let company = useSelector((state) => state.job.data);
    let user = getUser()
    const [open, setOpen] = React.useState(false)
    const [is_tabular, setIsTabular] = React.useState(false)
    const initJob = () => {
        let action = REQUESTS.JOB.GET.ACTION;
        let url = "/job/0/?filter_by=user_id=" + user?.id
        if (all) {
            url = "/job/0/"
        }
        dispatch({ type: action, payload: url });
    }
    React.useEffect(() => {
        initJob()
    }, [])
    React.useEffect(() => {
    }, [company])
    const onSubmit = (e, data) => {
        data["more_details"] = { "description": data.description, "vacancy": data.vacancy }
        let action = data.id
            ? REQUESTS.JOB.PATCH.ACTION
            : REQUESTS.JOB.POST.ACTION;
        dispatch({
            type: action, payload: data, callback: (resp) => {
                initJob()
                setOpen(false)
            }
        });
    };
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Container>
                {/* Company Logos ------------------ */}


                <Grid item xs={12}>
                    <Typography className='light-grey padding-top-20 padding-bottom-10'> We Helped to Grow</Typography>
                    <ZModal showModal={open} onClose={(s) => setOpen(false)} onAction={(s) => s == "Cancel" ? setOpen(false) : ""} title={"Post Job"}>
                        <Box sx={{ pt: 4 }}>
                            <XForm
                                onSubmit={onSubmit}>
                                <TextField
                                    fullWidth
                                    label="Job Title"
                                    name="job_title"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="More Details"
                                    variant="outlined"
                                    margin="normal"
                                    name="description"
                                />
                                <TextField
                                    fullWidth
                                    label="Vacancy"
                                    variant="outlined"
                                    margin="normal"
                                    name="vacancy"
                                />
                                <TextField
                                    fullWidth
                                    label="Category"
                                    name="category"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <input value={user.id} name="user" type="hidden" />
                                <Box sx={{ textAlign: 'center' }}>                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, maxWidth: '300px' }}  // Reduce the width of the button
                                >
                                    Continue
                                </Button>
                                </Box>

                            </XForm>
                        </Box>

                    </ZModal>
                    {getUser()?.main_role == "Company" && <Button onClick={() => { setOpen(true) }}
                        style={{ padding: 10, borderRadius: 0, background: '#2479b7', color: 'white' }}
                    >Post a New Job</Button>}
                </Grid>
                <Button onClick={() => setIsTabular(false)}>grid</Button><Button onClick={() => setIsTabular(true)}>Table</Button>
                <List
                    tabular={is_tabular}
                    results={company?.results}
                    title={getUser()?.main_role == "User" ? "All Jobs" : "Posted"}
                    user={user}
                    onApplayClick={
                        (data, status) => {
                            if (status == "view_applicant") {
                                console.log("Applied", data)
                                navigate("/applicants/", {
                                    state: { data: data, back: "/dashboard/" },
                                })
                            } else {
                                navigate("/job_details/", {
                                    state: { data: data, back: "/dashboard/" },
                                })
                            }
                        }
                    }
                />
            </Container>
        </>
    )
}

export default JobSearch