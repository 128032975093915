import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { NavLink, Link } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import { css } from "../content/Style";
import { ListItemText, Typography } from "@mui/material";
import { useHistory, useLocation } from 'react-router-dom';



/**
 * @author
 * @function MenuItem
 **/

const MenuBox = (props) => {
    const { name, subMenus, icon, onClick, to, exact, isExpand, open, drawerWidth, showLabel } = props;
    let location = useLocation()
    return (
        <li onClick={onClick} key={uuidv4()}>
            <Link
                key={uuidv4()}
                exact={"true"}
                to={to}
                className={`menu-item`}
                style={{ textDecoration: 'none', fontSize: 12, color: 'gray' }}
            >
                <Tooltip title={name} placement="right-start" TransitionComponent={Zoom}>
                    <Box disablePadding sx={{ display: 'block', textAlign: 'center' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 10,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2, py: 0,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    py: 0,
                                    justifyContent: open ? 'initial' : 'center',
                                    color: location.pathname == to ? css.SideBar?.activeIconColor : css.SideBar?.iconColor,
                                    fontSize: css.SideBar?.iconSize
                                }}
                            >
                                {icon}
                            </ListItemIcon>
                            {showLabel && drawerWidth >= 150 ? <Typography
                                sx={{ mx: 2, width: '100%', color: location.pathname == to ? css.SideBar?.activeIconColor : css?.SideBar?.color, fontSize: css?.SideBar?.fontSize }}>
                                {name}</Typography> : null}
                        </ListItemButton>
                        {(showLabel && drawerWidth < 150) && <Typography
                            sx={{ color: location.pathname == to ? css.SideBar?.activeIconColor : css?.SideBar?.color, fontSize: css?.SideBar?.fontSize }} >{name}</Typography>}
                    </Box>
                </Tooltip>

            </Link>
            {(subMenus && isExpand) && subMenus.length > 0 ? (
                <>
                    {subMenus.map((menu, index) => (
                        <span key={uuidv4()}>
                            <NavLink to={menu.to}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 20,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5, py: 0,

                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                fontSize: 10,
                                                py: 0,
                                                justifyContent: open ? 'initial' : 'center',
                                            }}
                                        >
                                            {menu.icon}
                                        </ListItemIcon>
                                        {showLabel ? <Typography sx={{ mx: 2 }} fontSize={13}>{name}</Typography> : null}
                                    </ListItemButton>
                                </ListItem>
                            </NavLink>
                        </span>
                    ))}
                </>
            ) : null
            }
        </li >
    );
};

export default MenuBox;
