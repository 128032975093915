import React from 'react'
import { Card, Box, Button, Container, Grid, Typography } from '@mui/material'
export default function FindMy({title}) {
    return (
        <Grid container justifyContent="center">
            <Grid item md={12}>
                <Box>
                    <Card sx={{ mb: 2, p: 4, boxShadow: 'none', textAlign: 'center', bgcolor: 'transparent' }}>
                        <Typography sx={{ fontSize: 42, fontWeight: 780, fontFamily: 'sans-serif' }}>
                            <span style={{ color: 'black' }}> Find your </span>
                            <span style={{ color: '#05b9f0' }}>
                                dream {title}
                            </span>
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                            <img src="https://assets-global.website-files.com/6480217dd2b60074b15929c5/6482d9e701243d4ce022dcb9_Homepage%20Hero%20Title%20Pattern.png" style={{ maxWidth: '50%', paddingLeft: '100px' }} />
                        </Box>
                        <Typography sx={{ fontSize: 18, fontWeight: 100, mt: 2 }}>
                            <span style={{ color: 'grey' }}> Find Your next career at companies like HubSpot, Nike, and DropBox</span>
                        </Typography>
                    </Card>
                </Box>
            </Grid>
        </Grid>)

}