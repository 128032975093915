import { Grid, Typography, Button, Box, TextField, Divider, Container } from '@mui/material';
import React from 'react';
import { useState } from "react";

import XForm from '../../libs/inputs/XForm';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUESTS } from "../../services/types";
import ButtonAppBar from '../home/fragments/ButtonAppBar';

export default function UserLogin() {

    let navigate = useNavigate();
    let dispatch = useDispatch()

    const [loading, setLoading] = useState(false);

    const onSubmit = (e, data) => {
        let action = data.id
            ? REQUESTS.USER.PATCH.ACTION
            : REQUESTS.USER.POST.ACTION;
        dispatch({ type: action, payload: data });
    };

    const [message, setMessage] = useState();

    return (
        <>
            <Container>
                <ButtonAppBar />

                <Box sx={{ flexGrow: 1, mt: 2, p: { xs: 2, md: 5 } }}>
                    <Grid container spacing={2} alignItems="center">

                        <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                            <img
                                loading="lazy"
                                src="https://uploads-ssl.webflow.com/6480217dd2b60074b15929c5/64896e2f14d2fc07ffa79526_Auth%20Page%20-%20Sidebar%20Illustration.png"
                                style={{ width: '100%', maxHeight: '480px', objectFit: 'cover' }}
                            />
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Box sx={{ mt: { xs: 1, md: 0 }, textAlign: 'center' }}>
                                {/* Get more opportunities text */}
                                <Typography variant="h4" gutterBottom>
                                    Welcome Back
                                </Typography>

                                <Button
                                    fullWidth
                                    variant="outlined"
                                    startIcon={<img src="https://www.google.com/favicon.ico" alt="Google" style={{ width: 24, height: 24 }} />}
                                    sx={{ mb: 2, maxWidth: '300px' }}
                                >
                                    Log in with Google
                                </Button>

                                <Divider>Or login with email</Divider>

                                <Box sx={{ mt: 2, maxWidth: '300px', mx: 'auto' }}>
                                    <XForm
                                        onSubmit={(e, data) => {
                                            setLoading(true);
                                            dispatch({
                                                type: REQUESTS.LOGIN.POST.ACTION,
                                                payload: data,
                                                cache: true,
                                                callback: (data) => {
                                                    console.log("user_permission", data);
                                                    setLoading(false);
                                                    if (data.token) {
                                                        navigate("/dashboard");
                                                    } else {
                                                        setMessage(data?.message);
                                                    }
                                                },
                                            });
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            variant="outlined"
                                            margin="normal"
                                            name="username"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            variant="outlined"
                                            margin="normal"
                                            name="password"
                                        />
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 2, maxWidth: '300px' }}  // Reduce the width of the button
                                        >
                                            Continue
                                        </Button>
                                        <Box className='error' sx={{ mt: 1 }}>{message}</Box>
                                    </XForm>
                                </Box>

                                <Typography variant="body2" sx={{ mt: 2 }} >
                                    Don't have an account? <a onClick={() => navigate('/sign_up/')}>Sign Up</a>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}
