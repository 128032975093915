import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Grid, CardMedia } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
// const CompanyCard = ({ data, onCompanyClick }) => {
//     return (
//         <Grid container>
//             <Grid item xs={12} md={12}>
//                 <Box 
//                     onClick={()=>{onCompanyClick(data)}}
//                     sx={{
//                         border: '1px solid #CCCCF5',
//                         borderRadius: 2,
//                         p: 2,
//                         mb: 5,
//                         mt: 2,
//                         '&:hover': {
//                             bgcolor: '#5235E8',
//                             color: 'white',
//                             '& svg, & h6, & p': {
//                                 color: 'white',
//                             }
//                         }
//                     }}
//                 >
//                     {/* Icon */}
//                     <CardMedia image={data.avatar_full_path}>
//                         <svg
//                             style={{ maxWidth: '40px', marginBottom: '10px', marginTop: '20px', color: '#6b6bff' }}
//                             xmlns="http://www.w3.org/2000/svg"
//                             viewBox="0 0 512 512"
//                         >
//                             <path fill="currentColor" d="M469.3 19.3l23.4 23.4c25 25 25 65.5 0 90.5l-56.4 56.4L322.3 75.7l56.4-56.4c25-25 65.5-25 90.5 0zM44.9 353.2L299.7 98.3 413.7 212.3 158.8 467.1c-6.7 6.7-15.1 11.6-24.2 14.2l-104 29.7c-8.4 2.4-17.4 .1-23.6-6.1s-8.5-15.2-6.1-23.6l29.7-104c2.6-9.2 7.5-17.5 14.2-24.2zM249.4 103.4L103.4 249.4 16 161.9c-18.7-18.7-18.7-49.1 0-67.9L94.1 16c18.7-18.7 49.1-18.7 67.9 0l19.8 19.8c-.3 .3-.7 .6-1 .9l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l64-64c.3-.3 .6-.7 .9-1l45.1 45.1zM408.6 262.6l45.1 45.1c-.3 .3-.7 .6-1 .9l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l64-64c.3-.3 .6-.7 .9-1L496 350.1c18.7 18.7 18.7 49.1 0 67.9L417.9 496c-18.7 18.7-49.1 18.7-67.9 0l-87.4-87.4L408.6 262.6z" />
//                         </svg>
//                     </CardMedia>


//                     {/* Title */}
//                     <Typography variant="h6" sx={{ mb: 1, mt: 4 }}>
//                         {data.first_name}
//                     </Typography>

//                     {/* Subtitle and Arrow */}
//                     <Grid container alignItems="center">
//                         <Grid item xs={8}>
//                             <Typography variant="body2" color="text.secondary">
//                                 {data?.jobs?.length||"No "} Jobs Available
//                             </Typography>
//                         </Grid>
//                         <Grid item xs={4} container justifyContent="flex-end">
//                             <IconButton size="small" sx={{ color: 'inherit' }}>
//                                 <ArrowForwardOutlinedIcon />
//                             </IconButton>
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </Grid>
//         </Grid>
//     );
// };

// export default CompanyCard;

const CompanyCard = ({ data, onCompanyClick }) => {
    return (
        <Box
            onClick={() => { onCompanyClick(data) }}
            sx={{
                border: '1px solid #D6DDEB',
                borderRadius: '8px',
                padding: '16px',
                textAlign: 'left',
                position: 'relative',
                transition: '0.3s', // Smooth transition for hover effects
                '&:hover': {
                    borderColor: '#5f49f2', // Changes border color on hover
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow on hover
                    cursor: 'pointer',
                },
            }}
        >
            {/* Jobs Badge */}
            <Box
                sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    backgroundColor: '#f3f1fe',
                    color: '#5f49f2',
                    padding: '4px 12px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                }}
            >
                3 Jobs
            </Box>

            {/* Company Logo */}
            <Box
                component="img"
                src="https://assets-global.website-files.com/6480217dd2b60074b15929c5/6482974e7db698c2a8007cdc_Discord%20Logo.svg" // Replace with actual logo URL
                alt="Company Logo"
                sx={{
                    width: '50px',
                    height: '50px',
                    marginBottom: '16px',
                    transition: '0.3s', // Smooth transition for image scale effect
                    '&:hover': {
                        transform: 'scale(1.1)', // Slightly enlarges the logo on hover
                    },
                }}
            />

            {/* Company Name */}
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#4e2a84' }}>
                DISCORD
            </Typography>

            {/* Company Description */}
            <Typography variant="body2" sx={{ color: '#555', marginBottom: '16px' }}>
                We'd love to work with someone like you. We care about creating a delightful experience.
            </Typography>

            {/* Category Chip */}
            <Chip
                label="Business Services"
                sx={{
                    borderColor: '#5f49f2',
                    color: '#5f49f2',
                    fontWeight: 'bold',
                    borderRadius: '16px',
                    padding: '0 8px',
                    transition: '0.3s', // Smooth transition for chip hover effect
                    '&:hover': {
                        backgroundColor: '#5f49f2',
                        color: '#fff',
                    },
                }}
                variant="outlined"
            />
        </Box>
    );
};

export default CompanyCard;

