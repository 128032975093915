import ImgInput from './ImgInput';
import './style.css'
export default function Input(props) {
    const objectConstructor = ({}).constructor;
    let { name, label, style, primary, secondary } = props
    const covercss = {
        position: 'relative',
        display: 'inline-block',
        marginBottom: 30,
        Padding: 3,
        boxSizing: 'border-box',
        width: '100%',
    }
    const labelCss = {
        position: 'absolute',
        top: -6,
        left: 5,
        margin: 'auto',
        boxSizing: 'border-box',
        fontSize: 11,
        background: '#ffff',
        paddingLeft: '4px',
        paddingRight: '4px',
        borderRadius: 8,
    }
    const inputCss = {
        width: '100%',
        boxSizing: 'border-box',
        height: 28,
        border: '1px solid #c5d6e9',
        borderRadius: 8,
        paddingLeft: 8,
        ...props?.style
    }
    label = label ? label : name
    const class_name = "float-input"
    const placeholder = label
    label = primary ? "" : label

    if (["hidden"].includes(props?.type)) {
        return (
            <input {...props} />
        )
    }
    if (["image"].includes(props?.type)) {
        return (
            <div style={covercss}>
                {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
                <ImgInput {...props} style={{ ...inputCss,paddingTop:4, paddingBottom:4}} />
                {(!primary && !secondary) && <label for={name}  style={labelCss}>{label}</label>}
            </div>
        )
    }

    if (["radio", "checkbox"].includes(props?.type)) {
        return (
            <div style={covercss}>
                <input {...props} /><label>{label}</label>
            </div>
        )
    }
    if (["textarea"].includes(props?.type)) {
        return (
            <div style={covercss}>
                {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
                <textarea id={name}  {...props} style={{ ...inputCss }} className={class_name} placeholder={label} ></textarea>
                {(!primary && !secondary) && <label for={name} className="floating-label" style={labelCss}>{label}</label>}
            </div>
        )
    }
    if (["select"].includes(props?.type)) {
        return (
            <div style={covercss}>
                {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
                <select className={class_name} {...props}>
                    <option value="">Select {props?.label ? props?.label : props?.name}</option>
                    {props?.options?.map((option, i) => objectConstructor == option.constructor ? <option key={i} value={option[props?.val]}>{option[props?.text]}</option> : <option>{option}</option>)}
                </select>
                {(!primary && !secondary) && <label for={name} className="floating-label" style={labelCss}>{label}</label>}
            </div>
        )
    }
    return (
        <div style={covercss}>
            {(primary && !secondary) && <div style={{ marginBottom: 4 }}>{placeholder}</div>}
            <input id={name}  {...props} style={{ ...inputCss }} className={class_name} placeholder={label} />
            {(!primary && !secondary) && <label for={name} className="floating-label" style={labelCss}>{label}</label>}
        </div>
    )
}