import { Button } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import { JOBAPPLICANT, REQUESTS, getUser } from "../../services/types";
import { useDispatch } from "react-redux";
import JobDetailCard from "./fragments/JobDetailCard";
import JobDesc from "./fragments/JobDesc";

function JobDetails({ onApplayClick }) {
    onApplayClick = onApplayClick?onApplayClick:(resp)=>{}
    let job = JSON.parse(localStorage.getItem("selectedjob") || '{}')
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const { state } = useLocation();
    const data = state?.data
    let back_url = state?.back ? state?.back : "/job_list/"

    console.log("PASSINg DATA", state?.data)
    job = data ? data : job
    console.log("JOB DATA", job)
    return <div>
        <Button onClick={() => navigate(back_url)}>Back</Button>
        <div className="job-title">
            <JobDetailCard onApplayClick={onApplayClick} job={job} title={job?.job_title} category={job?.category + "-" + job?.job_title} logo={job?.company?.avatar}/>
        </div>
        <div className="job-title">
            <JobDesc desc={job?.more_details?.description}/>
        </div>

       
    </div>
}
export default JobDetails