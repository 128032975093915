import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export default function PositionedMenu({icon}) {
let navigate=useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {icon}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}><Button onClick={() => navigate('/')}>Home</Button></MenuItem>
        <MenuItem onClick={handleClose} ><Button onClick={() => navigate('/job_list/')}>Find Jobs</Button></MenuItem>
        <MenuItem onClick={handleClose}><Button onClick={() => navigate('/company_list/')}>Browse Companies</Button></MenuItem>
        <MenuItem onClick={handleClose}><Button onClick={() => navigate('/login/')}>Login</Button></MenuItem>
        <MenuItem onClick={handleClose}><Button variant='contained' onClick={() => navigate('/sign_up/')} >Register</Button></MenuItem>
      </Menu>
    </div>
  );
}