import { Card, Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ButtonAppBar from '../home/fragments/ButtonAppBar'
import CompanyCard from '../company/fragments/CompanyCard'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Footer from '../footer/Footer'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUESTS } from "../../services/types";
import List from '../company/fragments/List';
import axios from "axios";
import SearchBox from '../jobs/fragments/SearchBox';
import FindMy from './FindMy';

function CompanySearch() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let company = useSelector((state) => state.pubuser.data);
    React.useEffect(() => {
        delete axios.defaults.headers.common['Authorization']
        let action = REQUESTS.PUBLICUSER.GET.ACTION;
        dispatch({ type: action, payload: "/public/user/0/?filter_by=main_role='Company'" });
        console.log("DATA", company)
    }, [])
    return (
        <>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Box>
                            <ButtonAppBar />
                        </Box>
                    </Grid>

                    <FindMy title={"company"}/>

                    <Grid item xs={12} sx={{ mb: 4 }}>
                        <SearchBox />
                    </Grid>
                </Grid>

                {/* Company Logos ------------------ */}
                <Grid container>
                    <List results={company?.results} onCompanyClick={(data) => {
                        console.log("Applied", data)
                        navigate("/company_jobs/", {
                            state: { data: data, back: "/dashboard/" },
                        })
                    }} />
                </Grid>
            </Container>
            <Grid container className='padding-top-20'>
                <Grid item xs={12} md={12}>
                    <Box>
                        <Footer />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default CompanySearch