import React from 'react'
import { Box, Container, Grid, TextField, Typography } from '@mui/material'

import ButtonAppBar from '../home/fragments/ButtonAppBar'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Footer from '../footer/Footer'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { REQUESTS, getUser } from "../../services/types";
import List from '../jobs/fragments/List';
import axios from 'axios';

function CompanyJobSearch() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let company = useSelector((state) => state.pubjob.data);
    const [open, setOpen] = React.useState(false)
    let userObj = getUser()
    const { state } = useLocation();
    const data = state?.data
    React.useEffect(() => {
        delete axios.defaults.headers.common['Authorization']
        let action = REQUESTS.PUBJOB.GET.ACTION;
        dispatch({ type: action, payload: `/public/job/0/?filter_by=user_id=${data?.id}`});
        console.log("USER ****", userObj)
    }, [])
    return (
        <>
            <Container>
                <Grid >
                    <Grid item xs={12} md={6}>
                        <Box>
                            <ButtonAppBar />
                        </Box>
                    </Grid>
                </Grid>


                {/* Company Logos ------------------ */}
                <Grid item xs={12}>
                    <Typography className='light-grey padding-top-20 padding-bottom-10'>Companies We Helped to Grow</Typography>
                </Grid>

                <Grid container>
                    <List title={data?.first_name} results={company?.results} onApplayClick={
                        (data) => {
                            if (userObj) {
                                setOpen(true)
                            } else {
                                localStorage.setItem("selectedjob",JSON.stringify(data))
                                navigate("/job_details/")
                            }
                        }
                    }
                    />
                </Grid>


            </Container>
            <Grid container className='padding-top-20'>
                <Grid item xs={12} md={12}>
                    <Box>
                        <Footer />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default CompanyJobSearch