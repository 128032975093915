import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box, Button
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { css as customCss } from '../content/Style';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function ZModal({
    title,
    children,
    showModal,
    onAction,
    successBtn,
    cancelBtn,
    maxWidth,
    onClose
}) {
    let ref = React.createRef();
    let refReset = React.createRef();
    const [fullScreen, setFullScreen] = React.useState(true);
    onAction = onAction?onAction:(action)=>{}
    onClose = onClose?onClose:(action)=>{}
    return (
        <>
            <Dialog
                open={showModal && children}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth={maxWidth?maxWidth:"xs"}
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    sx={{display:'flex',alignItems:'center',justifyContent:'space-between', p: 0.5, px: 1, ...customCss.Card.header }}
                >
                    <Typography >{title}</Typography>
                    <Typography variant="caption" >
                        <CloseOutlinedIcon onClick={onClose}/>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        {children}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {cancelBtn && <Button onClick={()=>{onAction("Cancel")}}>{cancelBtn}</Button>}

                    {successBtn && <Button onClick={()=>{onAction("Save")}}>{successBtn}</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}
