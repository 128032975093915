import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PositionedMenu from './PositionedMenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Hidden from '@mui/material/Hidden';
import { useNavigate } from 'react-router-dom';

export default function ButtonAppBar() {
  let navigate = useNavigate()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: '#ffff', boxShadow: 'none',px:2 , borderRadius:2}} >
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            
          </IconButton> */}

          <Box sx={{ flexGrow: 1 }}>

            <Box sx={{ display: { xs: 'flex',alignItems:'center' } }}>
              <Typography className='job-hunt-font job-hunt-font-14' variant="h6" component="div" sx={{ flexGrow: 0.1, color: '#333' }}>
                Job Huntly
              </Typography>

              <Typography className='light-grey find-jobs' sx={{ flexGrow: 0.05, display: { xs: 'none', md: 'block' } }} onClick={() => navigate('/')}>Home</Typography>
              <Typography className='light-grey find-jobs' sx={{ flexGrow: 0.05, display: { xs: 'none', md: 'block' } }} onClick={() => navigate('/job_list/')}>Find Jobs</Typography>
              <Typography className='light-grey' sx={{ flexGrow: 0.8, display: { xs: 'none', md: 'block' } }} onClick={() => navigate('/company_list/')}>Browse Companies</Typography>

            </Box>
          </Box>


          {/* <IconButton sx={{flexGrow:0.1}} ><ShoppingCartIcon/></IconButton> */}
          <Button sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => navigate('/login/')} >Login</Button>
          <Button variant='contained' className='register-color' sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => navigate('/sign_up/')} >Register</Button>

          <Hidden mdUp>

            <PositionedMenu icon={<MenuIcon />} />

          </Hidden>
        </Toolbar>
      </AppBar>
    </Box >
  );
}