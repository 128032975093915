import * as React from "react";
import { Box, Typography, Container, Divider, List, ListItem, ListItemIcon } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function JobDesc({desc}) {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={4}>
        <Box flex={2}>
          {/* About This Role */}
          <Box mb={4}>
            <Typography variant="h4" fontWeight={600} color="primary.main">
              About This Role
            </Typography>
            <Typography variant="body1" color="textSecondary" mt={2}>
             {desc}
            </Typography>
          </Box>

          {/* Categories */}
          <Box mb={4}>
            <Typography variant="h4" fontWeight={600} color="primary.main">
              Categories
            </Typography>
            <List sx={{ mt: 2 }}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Social Media Marketing
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Content Creation
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Community Management
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Marketing Strategy
                </Typography>
              </ListItem>
            </List>
          </Box>

          {/* Required Skills */}
          <Box mb={4}>
            <Typography variant="h4" fontWeight={600} color="primary.main">
              Required Skills
            </Typography>
            <List sx={{ mt: 2 }}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Proven experience in social media marketing and management.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Strong understanding of social media platforms and trends.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Excellent communication and writing skills.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Ability to analyze data and generate insights.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <Typography variant="body1" color="textSecondary">
                  Experience with social media management tools.
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>

        {/* Additional Information or Sidebar */}
        <Box flex={1} ml={{ md: 4 }}>
          <Box display="flex" flexDirection="column" alignItems="center" border={1} borderColor="divider" p={4}>
            <Typography variant="h6" fontWeight={600} color="text.primary">
              Additional Info
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" color="textSecondary">
              For more details about this job, please contact our HR department.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
