import React from 'react'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, TextField, InputAdornment, IconButton, Button, Avatar, Grid,
    Box, Chip, Menu, MenuItem, Rating
} from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FeatureCard from './ACard'

const ViewButtons = styled.div`
  display: flex;
`;

const ViewButton = styled(Button)`
  && {
    margin-left: 10px;
  }
`;
// Applicant  list
function List({ results, onClick, title, user, onApplayClick, tabular }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchText, setSearchText] = React.useState("");

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate()

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredApplicants = results?.filter(applicant =>
        applicant?.first_name.toLowerCase().includes(searchText.toLowerCase())
    );
    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={12}  >
                <Box className='padding-top-20 padding-bottom-10' >
                    <Typography sx={{ fontSize: '32px' }} >{title ? title : "All"} <span style={{ color: '#26A4FF' }} ></span></Typography>
                </Box>
            </Grid>
            {
                tabular ?
                    <> <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ padding: 2 }}>
                            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                                <Typography variant="h6">Total Applicants: {filteredApplicants.length}</Typography>
                                <TextField
                                    size="small"
                                    placeholder="Search Applicants"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleSearch}
                                />
                                <Button startIcon={<FilterListIcon />}>Filter</Button>
                                <ViewButtons>
                                    <ViewButton variant="outlined" onClick={() => navigate('/PiplineView/')} >Pipeline View</ViewButton>
                                    <ViewButton variant="contained" sx={{ bgcolor: '#5235E8' }}  >Table View</ViewButton>
                                </ViewButtons>
                            </Grid>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <input type="checkbox" />
                                            </TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell>Rating</TableCell>
                                            <TableCell>Hiring Stage</TableCell>
                                            <TableCell>Applied Date</TableCell>
                                            <TableCell>Job Role</TableCell>
                                            <TableCell>Action</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results?.map((applicant, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <input type="checkbox" />
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container alignItems="center">
                                                        <Avatar alt={applicant.name} src={`https://randomuser.me/api/portraits/${Math.floor(Math.random() * 100) % 2 === 0 ? 'men' : 'women'}/${index + 1}.jpg`} />
                                                        <Typography sx={{ marginLeft: 1 }}>{applicant.first_name}</Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Rating value={applicant.score} max={1} />
                                                    <Typography variant="body2" color="textSecondary">
                                                        {applicant.score}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={applicant.stage}
                                                        variant="outlined"
                                                        color={
                                                            applicant.stage === "Interview" ? "warning" :
                                                                applicant.stage === "Shortlisted" ? "primary" :
                                                                    applicant.stage === "Declined" ? "error" :
                                                                        applicant.stage === "Hired" ? "success" :
                                                                            applicant.stage === "Interviewed" ? "info" : "default"
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>{applicant.date}</TableCell>
                                                <TableCell>{applicant.role}</TableCell>
                                                <TableCell>
                                                    <Button variant="outlined">See Application</Button>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={handleMenuClick}>
                                                        <MoreVertIcon />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    </>
                    : results?.map((data) => <>
                        <Grid item xs={12} sm={3} md={3} key={data?.id}>
                            <Box>
                                <FeatureCard data={data} user={user} onApplayClick={onApplayClick} />
                            </Box>
                        </Grid>
                    </>)}
        </Grid>
    )
}

export default List