import { createAction } from '../libs/redux/saga'
export const AUTH_DATA = "auth";
export const LOGIN = "LOGIN";
export const UPDATEAUTH = "UPDATEAUTH";
export const getUser = ()=>JSON.parse(localStorage.getItem(AUTH_DATA))

// ---  Your Part Do not update above code----
export const PERFORMANCE="PERFORMANCE"
export const CHART = "CHART";
export const LOGOUT = "LOGOUT";
export const ISLOGGED = "ISLOGGED";
export const GROUP = "GROUP";
export const USERGROUP = "USERGROUP";
export const DASHBOARD = "DASHBOARD";
export const REPORT = "REPORT";
export const USER = "USER";
export const PUBLICUSER = "PUBLICUSER";
export const FORGOT = "FORGOT";
export const PAYMENTS = "PAYMENTS";
export const LOAN_DETAILS = "LOAN_DETAILS";
export const MAIL = "MAIL";
export const JOB = "JOB";
export const PROFILE = "PROFILE";
export const PUBJOB = "PUBJOB";
export const APPLYJOB = "APPLYJOB";
export const APPLIEDYJOB = "APPLIEDYJOB";
export const JOBAPPLICANT = "JOBAPPLICANT";
export const REQUESTS = {
    ...createAction(LOGOUT, "", AUTH_DATA),
    ...createAction(ISLOGGED, null, AUTH_DATA),
    ...createAction(UPDATEAUTH, "FETCHDIRECT", AUTH_DATA),
    ...createAction(LOGIN, "token", AUTH_DATA),
    ...createAction(FORGOT, "forgotpassword", "forgot"),
    ...createAction(CHART, "chart", "chart"),
    ...createAction(GROUP, "group", "group", { success: "User group updated", error: "Failed to add group" }),
    ...createAction(USERGROUP, "user/group", "usergroup", { success: "User group changed", error: "Failed to change group" }),
    ...createAction(DASHBOARD, "dashboard", "dashboard"),
    ...createAction(USER, "user", "user"),
    ...createAction(PUBLICUSER, "public/user", "pubuser",{ success: "Registration success", error: "Failed to change group" }),
    ...createAction(JOB, "job", "job",{ success: "Job successfully updated", error: "Failed to change job" }),
    ...createAction(PUBJOB, "public/job", "pubjob",{ }),
    ...createAction(PROFILE, "profile", "profile",{ }),
    ...createAction(APPLYJOB, "job/apply", "applyjob",{ success: "Job successfully applied", error: "Failed to apply job" }),
    ...createAction(APPLIEDYJOB, "job/applied", "appliedjob",{ success: "Job successfully applied", error: "Failed to apply job" }),
    ...createAction(JOBAPPLICANT, "job/applicant", "applicant",{ success: "Job successfully applied", error: "Failed to apply job" }),
    // ...createAction(PAYMENTS, "transactions", "payments", { message: true }),
    // ...createAction(LOAN_DETAILS, "loan_search", "loan_details", { message: true }),
}