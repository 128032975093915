import React from 'react';
import { Box, Button, Typography, Grid, Paper } from '@mui/material';

const DashboardComponent = () => {
  return (
    <Box sx={{ position: 'relative', backgroundColor: '#4D49FF', color: '#FFFFFF', padding: '80px 40px', overflow: 'hidden' }}>
      {/* Slanted Edges */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          backgroundColor: '#4D49FF',
          transform: 'skewY(-10deg)',
          transformOrigin: 'top right',
          zIndex: -1,
        }}
      />
      <Box sx={{ position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left Section */}
        <Grid container>
          
            <Box sx={{ flex: 1, paddingRight: 4 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                Start posting jobs today
              </Typography>
              <Typography variant="h6" sx={{ marginBottom: 3 }}>
                Start posting jobs for only $10.
              </Typography>
              <Button variant="contained" sx={{ backgroundColor: '#FFFFFF', color: '#4D49FF', fontWeight: 'bold' }}>
                Sign Up For Free
              </Button>
            </Box>
         
         
            {/* Right Section */}
            <Box sx={{ flex: 1 , display:{xs:'none', md:'block'}}}>
              <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
                <Typography className='grey' variant="h6" sx={{ marginBottom: 1 }}>Good morning, Maria</Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                  Here is your job listings statistic report from July 19 – July 25.
                </Typography>

                {/* Grid for Stats */}
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Paper elevation={1} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#4D49FF', color: '#FFFFFF' }}>
                      <Typography variant="h5">76</Typography>
                      <Typography variant="body2">New candidates to review</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={1} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#4D49FF', color: '#FFFFFF' }}>
                      <Typography variant="h5">3</Typography>
                      <Typography variant="body2">Schedule for today</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Paper elevation={1} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#4D49FF', color: '#FFFFFF' }}>
                      <Typography variant="h5">24</Typography>
                      <Typography variant="body2">Messages received</Typography>
                    </Paper>
                  </Grid>
                </Grid>

                {/* Additional Details */}
                <Box sx={{ marginTop: 3 }}>
                  <Typography variant="body2">Job Views: 2,342 (This Week 6.4%)</Typography>
                  <Typography variant="body2">Job Applied: 654 (This Week 0.5%)</Typography>
                </Box>
              </Paper>
            </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardComponent;
