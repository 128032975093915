import * as React from "react";
import { Badge, TextField, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card } from "@mui/material";
import { REQUESTS, getUser } from "../../services/types";
import { XAutoComplete, ViewSave } from "../../libs/Libs";
import CropImg from "../../libs/imgcrop/CropImg";
import { css as customCss } from '../../content/Style'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import GroupIcon from "@mui/icons-material/GroupOutlined";
import ChatBox from "../regular/ChatBox";
import JobSearch from "../jobs/JobSearch";
import List from "../jobs/fragments/List";
import { useNavigate } from "react-router-dom";
import ZModal from "../../libs/ZModal";
import JobCard from "../jobs/fragments/JobCard";
import JobDetails from "../jobs/JobDetails";
import SearchBox from "../jobs/fragments/SearchBox";
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [];


export default function Home() {
  let dispatch = useDispatch();
  let navigate = useNavigate()
  let userState = useSelector((state) => state.user.data);
  let dashboardState = useSelector((state) => state.dashboard);
  let job = null;
  try { job = JSON.parse(localStorage.getItem("selectedjob")) } catch (err) { }
  const [open, setOpen] = React.useState(Boolean(job))
  const [notify, setNotify] = React.useState(false)
  let jobs = useSelector((state) => state.job.data);
  const dynaInputs = [
    { name: "designation", type: "", label: "Your designation" },
    { name: "bio", type: "file", label: "Upload your bio data" },
    // {"experience_year":null},
    // {"experience":""},
    // {"skills":""},
    // {"social_media_links":null},
    // {"last_job":""},
    // {"current_job":""},
    // {"dob":""},
    // {"gender":""},
    // {"physically_challenged":false},
    // {"more_details":null},
    // {"contact_number":""},
    // {"email":""},
    // {"address":""},
    // {"nationality":""},
    // {"languages_spoken":""},
    // {"hobbies":""},
    // {"marital_status":""},
    // {"profile_picture":null},
    // {"bio":""},
    // {"education":null},
    // {"certifications":null},
    // {"interests":""},
    // {"availability":""},
    // {"preferred_work_location":""},
    // {"remote_work_preference":false},
    // {"relocation_willingness":false},
    // {"references":null}
  ]

  const init = () => {
    dispatch({ type: REQUESTS.USER.GET.ACTION, payload: 0 });
    dispatch({ type: REQUESTS.DASHBOARD.GET.ACTION, payload: 0 });
    let job_url = "/job/0/"
    let u = getUser()
    if (u.main_role == "User") {
      job_url = `/job/0/?filter_by=job_title='${u?.profile?.designation}'`
    }
    dispatch({ type: REQUESTS.JOB.GET.ACTION, payload: job_url });
    console.log("JOBS", jobs)
    Object.keys(u.profile).map((key) => {
      if (!u.profile[key] && !notify && u.main_role == "User") {
        setNotify(true)
      }
    })
    // setPaginate({ count: userState.data.count, next: userState.data.next, previous: userState.data.previous })
  };
  React.useEffect(() => { init(); console.log("JOBS", jobs) }, [])

  const onSubmit = (e, data, setLoading, setMessage) => {
    setLoading(true);
    let action = data["id"] > 0 ? REQUESTS.USER.PUT.ACTION : REQUESTS.USER.POST.ACTION
    dispatch({
      type: action,
      payload: data,
    });
    setLoading(false);
  };

  const onClickPagination = (num) => {
    dispatch({
      type: REQUESTS.USER.GET.ACTION,
      payload: `user/0/?page=${num + 1}`,
    });
  }

  return (
    <>
      <>
        <ChatBox
          onSubmit={(e, data) => {
            data["user"] = getUser()?.id
            let action = data?.id ? REQUESTS.PROFILE.PATCH.ACTION : REQUESTS.PROFILE.POST.ACTION
            dispatch({
              type: action, payload: data, callback: (resp) => {
                let u = getUser()
                u.profile = resp
                localStorage.setItem("auth", JSON.stringify(u))
                setNotify(false);
              }
            });
          }}
          inputs={dynaInputs}
          open={notify}
          data={getUser()?.profile}
          onClose={() => {
            console.log("DAAD",)
            setNotify(false);
          }} />
      </>

      <>
        {/* Show job details  on pop up which selected before logged in */}
        {(getUser().main_role == "User" && job) ?
          <>
            <ZModal showModal={job} onClose={() => {
              setOpen(false);
              localStorage.removeItem("selectedjob")
            }} >
              <JobDetails user={getUser()} data={job} onApplayClick={() => {
                setOpen(false);
                localStorage.removeItem("selectedjob")
                setNotify(true)
              }} />
            </ZModal>
          </>
          : <></>}
      </>
      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
        {dashboardState?.data?.results?.map((data, i) => {
          let cs = { ...customCss.Round }
          cs.color = customCss.Colors[parseInt(i % customCss.Colors.length)]?.color
          cs.backgroundColor = customCss.Colors[parseInt(i % customCss.Colors.length)]?.bg
          return <Grid item xs={6} md={3}>
            <Card display={'flex'} alignContent={'center'} alignItems={'center'} sx={{ ...customCss.Card.body, p: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Badge sx={cs}>
                  <GroupIcon />
                </Badge>
                <Typography sx={{ fontSize: 12 }}>{data.name}</Typography>

              </div>
              <div style={{ textAlign: 'right' }}><Typography component={'h1'}
                sx={{ fontSize: 14, background: customCss.Colors[1].bg, display: 'inline-block', p: .2, px: 3, borderRadius: 100 }}>{data.count}</Typography></div>
              <div></div>
            </Card>
          </Grid>
        })}
      </Grid> */}
      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ ...customCss.Card.body, p: 1 }}>
            <BarChart
              series={[
                { data: dashboardState?.data?.results?.map((data, i) => data.count) || [0], label: 'count', id: 'pvId' },
              ]}
              xAxis={[{ data: dashboardState?.data?.results?.map((data, i) => data.name) || [""], scaleType: 'band' }]}
              height={250}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ ...customCss.Card.body, p: 1 }}>
            <BarChart
              series={[
                { data: pData, label: 'pv', id: 'pvId' },
                { data: uData, label: 'uv', id: 'uvId' },
              ]}
              xAxis={[{ data: xLabels, scaleType: 'band' }]}
              height={250}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>

        <Grid item xs={12} md={12}>
          <PieChart
            series={[
              {
                data: dashboardState?.data?.results?.map((data, i) => { return { id: i, value: data.count, label: data.name } })||[]
              },
            ]}
            height={200}
          />
        </Grid>
      </Grid> */}
      <Grid sx={{ mb: 4 }}>
        <SearchBox />
      </Grid>
      <Grid container>
        <List
          user={getUser()}
          title={getUser()?.main_role == "Company" ? "Posted Jobs" : "Recommended jobs for you"}
          results={jobs?.results} onApplayClick={
            (data, status) => {
              if (status == "view_applicant") {
                console.log("Applied", data)
                navigate("/applicants/", {
                  state: { data: data, back: "/dashboard/" },
                })
              } else {
                navigate("/job_details/", {
                  state: { data: data, back: "/dashboard/" },
                })
              }
            }
          }
        />
      </Grid>
    </>
  );
}
