import * as React from "react";
import { Grid, Box, Typography, Button, TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";

export default function SearchBox() {
  return (
    <Box 
      sx={{ 
        width: 'auto', 
        fontSize: 16, 
        fontWeight: 400, 
        lineHeight: "160%", 
        marginTop: 4,
        bgcolor: 'transparent',
        padding: 2,
        boxShadow: "0px 79px 128px rgba(192, 192, 192, 0.09), 0px 28.836px 46.722px rgba(192, 192, 192, 0.06), 0px 13.999px 22.683px rgba(192, 192, 192, 0.05), 0px 6.863px 11.119px rgba(192, 192, 192, 0.04), 0px 2.714px 4.397px rgba(192, 192, 192, 0.03)",
        backgroundColor: "#fff",
        borderRadius:5,
      }}
    >
      <Grid 
        container 
        spacing={2} 
        sx={{
          alignItems: "center",
          width: '100%', // Full width
          "@media (max-width: 'auto)": { flexWrap: "wrap" }
        }}
      >
        <Grid item xs={12} md={5} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f7d2d193db6251ea4757715e35a2f044527b213a4c7437134260b59ad135df2?apiKey=3cdd01a866254c9ba27f523a88497719&"
            alt="job icon"
            style={{ aspectRatio: "1", objectFit: "auto", objectPosition: "center", width: 24 }}
          />
          <Box sx={{ flex: 1 }}>
            <TextField 
              fullWidth
              label="Job title or keyword"
              variant="standard" // Use standard variant for underline style
              size="small"
              sx={{ marginTop: 1 }}
            />
          </Box>
        </Grid>
       
        <Grid item xs={12} md={5} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3611dfed57339ce8e7fb4603be9b4c1c4474aa341b5a3b76d9cbcfcd5e74bcf5?apiKey=3cdd01a866254c9ba27f523a88497719&"
            alt="location icon"
            style={{ aspectRatio: "1", objectFit: "auto", objectPosition: "center", width: 24 }}
          />
          <Box sx={{ flex: 1 }}>
            <FormControl fullWidth size="small" sx={{ marginTop: 1 }}>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                labelId="location-label"
                id="location-select"
                value=""
                label="Location"
                variant="standard" // Use standard variant for underline style
              >
                <MenuItem value="florence">Florence</MenuItem>
                <MenuItem value="rome">Rome</MenuItem>
                <MenuItem value="milan">Milan</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button 
            fullWidth 
            variant="contained" 
            sx={{
              backgroundColor: "#4640de",
              color: "#fff",
              textAlign: "center",
              padding: "6px 12px",  // Reduced padding
              fontSize: 14,          // Reduced font size
              fontWeight: 700,
              fontFamily: "Epilogue, sans-serif"
            }}
          >
            Search my job
          </Button>
        </Grid>
      </Grid>

      {/* <Typography 
        sx={{ 
          color: "#202430", 
          fontFamily: "Epilogue, sans-serif", 
          marginTop: 2, 
          marginLeft:2,
          width: "100%", 
          "@media (max-width: 991px)": { maxWidth: "100%" } 
        }}
      >
        Popular:{" "} 
        <span style={{ fontWeight: 500 }}>
          UI Designer, UX Researcher, Android, Admin
        </span>
      </Typography> */}
    </Box>
  );
}
