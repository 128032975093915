import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Divider,
  CardMedia,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";

export default function JobCard({ data, user, onApplayClick }) {
  let navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showButtons = () => {
    if (data?.is_applied) {
      
      if (user?.main_role == "Company") {
        return <Chip sx={{ color: "green" }} label="View Applicant" onClick={() => { onApplayClick(data,"view_applicant") }}/>
      }
      return <Box sx={{ color: "green" }}>Applied</Box>
    }
    if (user?.main_role == "Company") {
      return <>
       {/* <Chip sx={{ color: "gray" }} label="No Applicant"  onClick={() => { onApplayClick(data,"info") }}/> */}
      </>
    }
    return (<>
     
      <Button
        onClick={() => { onApplayClick(data,"Apply") }}
        variant="contained"
        sx={{
          backgroundColor: '#4640de',
          color: '#fff',
          fontWeight: 700,
          textTransform: 'none',
          width: isMobile ? '100%' : '170px', // Increased width for desktop view
          py: 1.5,
          mt: isMobile ? 2 : 0,
          transition: 'background-color 0.3s ease, transform 0.3s ease',
          '&:hover': {
            backgroundColor: '#3630be',
            transform: 'translateY(-2px)',
          },
        }}
      >
        Apply
      </Button>
    </>
    )
  }
  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'rgba(214, 221, 235, 1)',
        borderRadius: 2,
        p: 3,
        maxWidth: 'auto',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md={7} container spacing={2}>
          <Grid item>
            <Avatar
              variant="square"
              sx={{
                width: 64,
                height: 64,
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'rotate(10deg) scale(1.1)',
                },
              }}
              src={data?.company?.avatar ? "" : "https://cdn.builder.io/api/v1/image/assets/TEMP/d23adaa58b1a453e1ea3aac6f4cc5bac02203d6c27d703e02ccf7f09222a03d6?apiKey=3cdd01a866254c9ba27f523a88497719&"}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h6" color="text.primary">
              {data?.job_title}
            </Typography>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Nomad
                </Typography>
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Paris, France
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              sx={{ mt: 1 }}
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{
                    backgroundColor: 'rgba(86, 205, 173, 0.1)',
                    color: '#56cdad',
                    px: 1,
                    py: 0.5,
                    borderRadius: 80,
                    whiteSpace: 'nowrap',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#56cdad',
                      color: '#fff',
                    },
                  }}
                >
                  Full-Time
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'rgba(214, 221, 235, 1)', mx: 1 }} />
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{
                    border: 1,
                    borderColor: 'rgba(255, 184, 54, 1)',
                    color: '#ffb836',
                    px: 1,
                    py: 0.5,
                    borderRadius: 80,
                    whiteSpace: 'nowrap',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#ffb836',
                      color: '#fff',
                    },
                  }}
                >
                  Marketing
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ bgcolor: 'rgba(214, 221, 235, 1)', mx: 1 }} />
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{
                    border: 1,
                    borderColor: 'rgba(70, 64, 222, 1)',
                    color: '#4640de',
                    px: 1,
                    py: 0.5,
                    borderRadius: 80,
                    whiteSpace: 'nowrap',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#4640de',
                      color: '#fff',
                    },
                  }}
                >
                  Design
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} container direction="column" alignItems="center" spacing={2}>
        {/* {JSON.stringify(data)} */}
          {showButtons()}
          <Box mt={2} textAlign="center">
            <CardMedia
              component="img"
              sx={{ width: isMobile ? '100%' : 164, mx: 'auto' }}
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/15b2c157c424782007e34bbaeba098a8101b9d33b776ee366a4c75f3ff429b65?apiKey=3cdd01a866254c9ba27f523a88497719&"
            />
            <Typography variant="body2" color="text.secondary" mt={1}>
              <span style={{ fontWeight: 600, color: 'rgba(37,50,75,1)' }}>
                {data?.applied_users?.length} applied</span>{" "}
              <span style={{ color: 'rgba(124,132,147,1)' }}>of {data?.more_details?.vacancy} capacity</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
