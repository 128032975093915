import { Grid, Typography, Button, Box, TextField, Divider, Tab, Container } from '@mui/material';
import React from 'react';
import XForm from '../../libs/inputs/XForm';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUESTS } from "../../services/types";
import axios from 'axios';
import { TabList, TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import ButtonAppBar from '../home/fragments/ButtonAppBar'

export default function SignUp() {
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const [tab, setTab] = React.useState(1 + "")
    const onSubmit = (e, data) => {
        if (tab == "2") {
            data.main_role = "Company"
        }
        delete axios.defaults.headers.common['Authorization']
        let action = data.id
            ? REQUESTS.PUBLICUSER.PATCH.ACTION
            : REQUESTS.PUBLICUSER.POST.ACTION;
        dispatch({
            type: action, payload: data, callback: (resp) => {
                console.log("callback", resp)
                if (resp?.token) {
                    dispatch({ type: REQUESTS.UPDATEAUTH.POST.ACTION, payload: resp })
                    navigate("/")
                }
            }
        });
    };

    return (<>
        <Container>
            <ButtonAppBar />
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={5} display={{ xs: 'none', md: 'block' }}>
                    <Typography variant="h4" gutterBottom>
                        Get more opportunities
                    </Typography>
                    <img
                        loading="lazy"
                        src="https://uploads-ssl.webflow.com/6480217dd2b60074b15929c5/64896e2f14d2fc07ffa79526_Auth%20Page%20-%20Sidebar%20Illustration.png"
                        style={{ width: '100%', maxHeight: '480px', objectFit: 'cover' }}
                    />
                </Grid>

                <Grid item xs={12} md={7}>
                    <TabContext value={tab}>
                        <Box sx={{ mt: { xs: 1, md: 0 }, textAlign: 'center' }}>
                            {/* Adjusted Grid and Button width */}
                            {/* Get more opportunities text */}

                            <Box sx={{ mt: 2, maxWidth: '320px', mx: 'auto' }}>
                                <TabList centered onChange={(e, v) => { setTab(v); console.log(v) }} aria-label="lab API tabs example">
                                    <Tab label="Job Seeker" value="1" />
                                    <Tab label="Company" value="2" />
                                </TabList>
                                <TabPanel value="1">
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        startIcon={<img src="https://www.google.com/favicon.ico" alt="Google" style={{ width: 24, height: 24 }} />}
                                        sx={{ mb: 2, maxWidth: '300px' }}
                                    >
                                        Sign Up with Google
                                    </Button>
                                    <Divider>Or login with email</Divider>


                                    <XForm
                                        onSubmit={onSubmit}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="first_name"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Phone Number"
                                            variant="outlined"
                                            margin="normal"
                                            name="phone"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="username"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            variant="outlined"
                                            margin="normal"
                                            name="password"
                                        />
                                        <input type="hidden" value="User" name="main_role" />

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 2, maxWidth: '300px' }}  // Reduce the width of the button
                                        >
                                            Continue
                                        </Button>
                                    </XForm>

                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Already have an account? <a onClick={() => navigate('/login/')}>Login</a>
                                    </Typography>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        startIcon={<img src="https://www.google.com/favicon.ico" alt="Google" style={{ width: 24, height: 24 }} />}
                                        sx={{ mb: 2, maxWidth: '300px' }}
                                    >
                                        Sign Up with Google
                                    </Button>
                                    <Divider>Or login with email</Divider>


                                    <XForm
                                        onSubmit={onSubmit}>
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            name="first_name"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Company Phone Number"
                                            variant="outlined"
                                            margin="normal"
                                            name="phone"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Company Email"
                                            name="username"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            variant="outlined"
                                            margin="normal"
                                            name="password"
                                        />
                                        <input type="hidden" value="Company" name="main_role" />

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 2, maxWidth: '300px' }}  // Reduce the width of the button
                                        >
                                            Continue
                                        </Button>
                                    </XForm>

                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        Already have an account? <a onClick={() => navigate('/login/')}>Login</a>
                                    </Typography>
                                </TabPanel>
                            </Box>
                        </Box>
                    </TabContext>
                </Grid>
            </Grid>
        </Container>
    </>
    );
}
