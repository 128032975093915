import { Button, Grid, Typography, Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

export default function Footer() {
  return (
    <FooterContainer>
      <Grid container spacing={4} sx={{ maxWidth: 1192, mx: 'auto', px: {  sm: 3 } }}>
        <Grid item xs={12} sm={6} md={4}>
          <Box display="flex" alignItems="center" gap={1}  sx={{pt:2}}>
            <StyledImg
              loading="lazy"
             
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ccae55800a6edc745e1bc3fcfc76c0afe0a0f1c5286afb3c4d403a37169740d?apiKey=3cdd01a866254c9ba27f523a88497719&"
            />
            <Typography variant="h5" component="div" fontWeight={700} sx={{color:'white'}} >
              JobHuntly
            </Typography>
          </Box>
          <Typography variant="body1" mt={5} color="textSecondary" sx={{color:'white'}} >
            Great platform for the job seeker that passionate about startups. Find your dream job easier.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" component="div" sx={{color:'white'}} >
            About
          </Typography>
          <Box mt={3} display="flex" flexDirection="column" gap={2} sx={{color:'white'}} >
            {["Companies", "Pricing", "Terms", "Advice", "Privacy Policy"].map((text) => (
              <Typography key={text} variant="body2" component="div" color="white" sx={{ cursor: 'pointer' }}>
                {text}
              </Typography>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" component="div" sx={{color:'white'}} >
            Resources
          </Typography>
          <Box mt={3} display="flex" flexDirection="column" gap={2} color='white' >
            {["Help Docs", "Guide", "Updates", "Contact Us"].map((text) => (
              <Typography key={text} variant="body2" component="div" color="white" sx={{ cursor: 'pointer' }}>
                {text}
              </Typography>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" component="div" sx={{color:'white'}} >
            Get job notifications
          </Typography>
          <Typography variant="body2" mt={3} color="textSecondary" sx={{color:'white'}} >
            The latest job news, articles, sent to your inbox weekly.
          </Typography>
          <Box mt={5} display="flex" gap={2}>
            <Box
              component="div"
              sx={{
                border: 1,
                borderColor: 'rgba(214, 221, 235, 1)',
                bgcolor: 'background.paper',
                color: 'textSecondary',
                p: 2,
                flexGrow: 1,
              }}
            >
              Email Address
            </Box>
            <Button variant="contained">Subscribe</Button>
          </Box>
        </Grid>
      </Grid>

      <Box mt={15} display="flex" gap={2} flexWrap="wrap" justifyContent="center">
        <Typography variant="body2" component="div" color="textSecondary" sx={{color:'white'}} >
          2021 @ JobHuntly. All rights reserved.
        </Typography>
        <StyledImg
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddac5565f16886e1ffde1d96d549eb0440cc328883ea806c8136d1709475ad8d?apiKey=3cdd01a866254c9ba27f523a88497719&"
          sx={{ width: 256, maxWidth: '100%', cursor: 'pointer' }}
        />
      </Box>
    </FooterContainer>
  );
}

const FooterContainer = styled(Box)`
  background-color: #202430;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 66px 60px 40px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StyledImg = styled("img")`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 32px;
`;

