import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useSelector } from 'react-redux';



function GroupAvatars({ images }) {
  return (<>
    <AvatarGroup max={1} total={images?.length} sx={{ p: 0 }}>
      {images && images?.map((image) => <Avatar alt="Remy Sharp" src={image} />)}
      {(images && images?.length == 0) && <Chip label="No Applicants" />}
    </AvatarGroup>
  </>
  );
}

const ACard = ({ data, user, onApplayClick }) => {
  let userState = useSelector((state) => state.user.data);
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Box sx={{ border: '1px solid #CCCCF5', mb: 1 }}>
          <CardContent>
            {/* Company Logo and Job Type */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1, mt: 1 }}
              onClick={() => { onApplayClick(data) }}
            >
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}><img src="https://imgs.search.brave.com/Yk3pO_ZsEavXLn2V3Cz8fC4iAyXLcMV5p-F64IiwcgM/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMtMDAuaWNvbmR1/Y2suY29tL2Fzc2V0/cy4wMC9kcm9wYm94/LWljb24tMjU2eDIx/Ny1ycW45bWFlNC5w/bmc"
                    alt="Company Logo" style={{ maxWidth: '35px' }} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div >
                      {(data?.is_applied && user?.main_role=="User") ? <div style={{color:"green"}}>Applied</div> :<></>}

                      {(user?.main_role == "Company") && <>
                        <GroupAvatars images={data?.applied_users?.map((user) => user.avatar)} />
                      </>}
                    </div>
                  </Grid>

                </Grid>

                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2, mt: 2 }}>
                  {data?.first_name}
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                  {data?.more_details?.description}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={4} md={4}>
                    <Chip label="Marketing" sx={{ backgroundColor: '#FFF5E5', color: '#FFA726' }} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Chip label="Design" sx={{ backgroundColor: '#E5F6F5', color: '#26C6DA' }} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <Chip label="Full Time" variant="outlined" sx={{ color: '#4D49FF', borderColor: '#4D49FF', fontWeight: 'bold' }} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ACard;
