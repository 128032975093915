import { Box, Button, Container, Grid, Typography, Modal, TextField } from '@mui/material'
import React from 'react'
import ButtonAppBar from '../home/fragments/ButtonAppBar'
import FeatureCard from './fragments/ACard'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Footer from '../footer/Footer'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { REQUESTS, getUser } from "../../services/types";
import List from './fragments/List';
import ZModal from '../../libs/ZModal';
import { XForm } from '../../libs/Libs';

function ApplicantSearch({ all }) {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let company = useSelector((state) => state.applicant.data);
    let user = getUser()
    const [open, setOpen] = React.useState(false)
    const [is_tabular, setIsTabular] = React.useState(false)
    const { state } = useLocation();
    const data = state?.data
    const initJob = () => {
        let action = REQUESTS.JOBAPPLICANT.GET.ACTION;
        let url = "/user/0/"
        if (all) {
            url = "/user/0/"
        }
        if(data?.id) all = false
        console.log("Selected job", data?.id, "All", all)
        dispatch({ type: action, payload: (data?.id && !all)?data?.id:0 });
    }
    React.useEffect(() => {
        initJob()
       
    }, [])
    React.useEffect(() => {
        console.log("APPLICANTxxx", company)
    }, [company])
    const onSubmit = (e, data) => {
        data["more_details"] = { "description": data.description, "vacancy": data.vacancy }
        let action = data.id
            ? REQUESTS.JOB.PATCH.ACTION
            : REQUESTS.JOB.POST.ACTION;
        dispatch({
            type: action, payload: data, callback: (resp) => {
                initJob()
                setOpen(false)
            }
        });
    };
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Container>
                {/* Company Logos ------------------ */}
                <Grid item xs={12}>
                    <ZModal showModal={open} onClose={(s) => setOpen(false)} onAction={(s) => s == "Cancel" ? setOpen(false) : ""} title={"Post Job"}>
                        <Box sx={{ pt: 4 }}>
                            <XForm
                                onSubmit={onSubmit}>
                                <TextField
                                    fullWidth
                                    label="Job Title"
                                    name="job_title"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="More Details"
                                    variant="outlined"
                                    margin="normal"
                                    name="description"
                                />
                                <TextField
                                    fullWidth
                                    label="Vacancy"
                                    variant="outlined"
                                    margin="normal"
                                    name="vacancy"
                                />
                                <TextField
                                    fullWidth
                                    label="Category"
                                    name="category"
                                    variant="outlined"
                                    margin="normal"
                                />
                                <input value={user.id} name="user" type="hidden" />
                                <Box sx={{ textAlign: 'center' }}>                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, maxWidth: '300px' }}  // Reduce the width of the button
                                >
                                    Continue
                                </Button>
                                </Box>

                            </XForm>
                        </Box>

                    </ZModal>
                </Grid>

                <Button onClick={()=>setIsTabular(false)}>grid</Button><Button onClick={()=>setIsTabular(true)}>Table</Button>
                <List tabular={is_tabular} results={company?.results} title={getUser()?.main_role=="User"?"All Jobs":"Applicants of " + (data?.job_title?data?.job_title:"")} user={user} />
            </Container>
        </>
    ) 
}

export default ApplicantSearch