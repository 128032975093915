import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import CompanyCard from './CompanyCard'
import { useNavigate } from "react-router-dom";

// company list
function List({ results, onCompanyClick }) {
    let navigate = useNavigate()

    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12}  >
                    <Box className='padding-top-20 padding-bottom-10' >
                        <Typography sx={{ fontSize: '32px' }} >All <span style={{ color: '#26A4FF' }} >Companies</span></Typography>
                    </Box>
                </Grid>
                {results?.map((data) => <>
                    <Grid item xs={12} sm={3} md={3} key={data.id}>
                        <CompanyCard data={data} key={data.id} onCompanyClick={onCompanyClick}/>
                    </Grid>
                </>)}
                <Grid item xs={12} md={3}>
                    {(!results || results.length == 0) && <Box>No Records available</Box>}
                </Grid>

            </Grid>

        </>
    )
}

export default List