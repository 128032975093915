import React, { useState } from "react";
import { Container, Grid, Checkbox, Typography, Box, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const employmentTypes = [
  { label: "Full-time", count: 3 },
  { label: "Part-Time", count: 5 },
  { label: "Remote", count: 2 },
  { label: "Internship", count: 24 },
  { label: "Contract", count: 3 }
];

const categoriesData = [
  {
    title: "Categories",
    data: [
      { label: "Design", count: 24 },
      { label: "Sales", count: 3 },
      { label: "Marketing", count: 3 },
      { label: "Business", count: 3 },
      { label: "Human Resource", count: 6 },
      { label: "Finance", count: 4 },
      { label: "Engineering", count: 4 },
      { label: "Technology", count: 5 }
    ]
  },
  {
    title: "Job Levels",
    data: [
      { label: "Entry Level", count: 57 },
      { label: "Mid Level", count: 3 },
      { label: "Senior Level", count: 5 },
      { label: "Director", count: 12 },
      { label: "VP or Above", count: 8 }
    ]
  },
  {
    title: "Salary Ranges",
    data: [
      { label: "$700 - $1000", count: 4 },
      { label: "$100 - $1500", count: 6 },
      { label: "$1500 - $2000", count: 10 },
      { label: "$3000 or above", count: 4 }
    ]
  }
];

const SideFilter = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [expandedSections, setExpandedSections] = useState({
    employmentTypes: true,
    categories: true,
    jobLevels: true,
    salaryRanges: true
  });

  const handleCategoryToggle = (label) => {
    if (selectedCategories.includes(label)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== label));
    } else {
      setSelectedCategories([...selectedCategories, label]);
    }
  };

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  return (
    <Container>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h5"
            component="div"
            fontWeight='bold'
            onClick={() => toggleSection("employmentTypes")}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              transition: 'color 0.3s ease',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            Type of Employment
            <IconButton size="small">
              {expandedSections.employmentTypes ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          </Typography>
          {expandedSections.employmentTypes && employmentTypes.map((type, index) => (
            <Grid container alignItems="center" key={index} sx={{ marginTop: 1, transition: 'background-color 0.3s ease', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' } }}>
              <Checkbox
                checked={selectedCategories.includes(type.label)}
                onChange={() => handleCategoryToggle(type.label)}
                sx={{ transition: 'color 0.3s ease', '&:hover': { color: 'primary.main' } }}
              />
              <Typography variant="body1">{`${type.label} (${type.count})`}</Typography>
            </Grid>
          ))}
        </Grid>

        {categoriesData.map(({ title, data }, sectionIndex) => (
          <Grid item key={sectionIndex}>
            <Typography
              variant="h6"
              component="div"
              onClick={() => toggleSection(title.toLowerCase())}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {title}
              <IconButton size="small">
                {expandedSections[title.toLowerCase()] ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            </Typography>
            <Box
              sx={{
                overflow: 'hidden',
                transition: 'max-height 0.3s ease',
                maxHeight: expandedSections[title.toLowerCase()] ? '200px' : '0',
              }}
            >
              {expandedSections[title.toLowerCase()] && data.map((item, itemIndex) => (
                <Grid container alignItems="center" key={itemIndex} sx={{ marginTop: 1, transition: 'background-color 0.3s ease', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' } }}>
                  <Checkbox
                    checked={selectedCategories.includes(item.label)}
                    onChange={() => handleCategoryToggle(item.label)}
                    sx={{ transition: 'color 0.3s ease', '&:hover': { color: 'primary.main' } }}
                  />
                  <Typography variant="body1">{`${item.label} (${item.count})`}</Typography>
                </Grid>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SideFilter;
